import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { AuthProvider } from './contexts/AuthContext';
import { useScrollToTop } from './hooks/useScrollToTop';

// Global error handler with improved error reporting
const setupErrorHandlers = () => {
  // Store original handlers
  const originalOnError = window.onerror;
  const originalOnUnhandledRejection = window.onunhandledrejection;

  // Enhanced error handler
  window.onerror = function(msg, url, line, col, error) {
    // Log to console with full details
    console.error('Global error:', {
      message: msg,
      url,
      line,
      col,
      error: error?.stack || error
    });

    // Call original handler if it exists
    if (typeof originalOnError === 'function') {
      return originalOnError.call(this, msg, url, line, col, error);
    }

    // Prevent the error from bubbling up
    return true;
  };

  // Enhanced promise rejection handler
  window.onunhandledrejection = function(event) {
    // Log to console with full details
    console.error('Unhandled promise rejection:', {
      reason: event.reason?.stack || event.reason
    });

    // Call original handler if it exists
    if (typeof originalOnUnhandledRejection === 'function') {
      originalOnUnhandledRejection.call(this, event);
    }

    // Prevent the error from bubbling up
    event.preventDefault();
  };
};

// Error Boundary Component
class ErrorBoundary extends React.Component<
  { children: React.ReactNode; fallback?: React.ReactNode },
  { hasError: boolean; error: Error | null }
> {
  constructor(props: { children: React.ReactNode; fallback?: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error('Error caught by boundary:', {
      error: error?.stack || error,
      componentStack: info.componentStack
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="text-center p-8 max-w-md">
            <h1 className="text-2xl font-bold mb-4">Something went wrong</h1>
            <p className="text-gray-600 mb-6">
              {this.state.error?.message || 'An unexpected error occurred'}
            </p>
            <button
              onClick={() => {
                this.setState({ hasError: false, error: null });
                window.location.reload();
              }}
              className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 transition-colors"
            >
              Try again
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Loading component
function PageLoader() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
    </div>
  );
}

// Lazy load components with error handling
const lazyLoad = (importFn: () => Promise<any>, name: string) => {
  return React.lazy(() =>
    importFn().catch((error) => {
      console.error(`Error loading ${name}:`, error);
      return { default: NotFound };
    })
  );
};

// Lazy load pages with error boundaries
const Services = lazyLoad(() => import('./pages/Services').then(module => ({ default: module.Services })), 'Services');
const About = lazyLoad(() => import('./pages/About').then(module => ({ default: module.About })), 'About');
const Contact = lazyLoad(() => import('./pages/Contact').then(module => ({ default: module.Contact })), 'Contact');
const Portfolio = lazyLoad(() => import('./pages/Portfolio').then(module => ({ default: module.Portfolio })), 'Portfolio');
const Blog = lazyLoad(() => import('./pages/Blog').then(module => ({ default: module.Blog })), 'Blog');
const BlogPost = lazyLoad(() => import('./pages/BlogPost').then(module => ({ default: module.BlogPost })), 'BlogPost');
const AdminDashboard = lazyLoad(() => import('./pages/admin/Dashboard').then(module => ({ default: module.AdminDashboard })), 'AdminDashboard');
const AdminLogin = lazyLoad(() => import('./pages/admin/Login').then(module => ({ default: module.AdminLogin })), 'AdminLogin');
const BlogManagement = lazyLoad(() => import('./pages/admin/BlogManagement').then(module => ({ default: module.BlogManagement })), 'BlogManagement');
const PrivacyPolicy = lazyLoad(() => import('./pages/legal/PrivacyPolicy').then(module => ({ default: module.PrivacyPolicy })), 'PrivacyPolicy');
const Terms = lazyLoad(() => import('./pages/legal/Terms').then(module => ({ default: module.Terms })), 'Terms');
const Disclaimer = lazyLoad(() => import('./pages/legal/Disclaimer').then(module => ({ default: module.Disclaimer })), 'Disclaimer');
const Complaints = lazyLoad(() => import('./pages/legal/Complaints').then(module => ({ default: module.Complaints })), 'Complaints');
const Partners = lazyLoad(() => import('./pages/legal/Partners').then(module => ({ default: module.Partners })), 'Partners');
const Partner = lazyLoad(() => import('./pages/Partner').then(module => ({ default: module.Partner })), 'Partner');
const Affiliate = lazyLoad(() => import('./pages/Affiliate').then(module => ({ default: module.Affiliate })), 'Affiliate');
const AffiliateLogin = lazyLoad(() => import('./pages/affiliate/Login').then(module => ({ default: module.AffiliateLogin })), 'AffiliateLogin');
const AffiliateDashboard = lazyLoad(() => import('./pages/affiliate/Dashboard').then(module => ({ default: module.AffiliateDashboard })), 'AffiliateDashboard');

function ScrollToTop() {
  useScrollToTop();
  return null;
}

// App component with improved error handling
export default function App() {
  useEffect(() => {
    // Set up error handlers
    setupErrorHandlers();

    // Clean up error handlers
    return () => {
      window.onerror = null;
      window.onunhandledrejection = null;
    };
  }, []);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <div className="min-h-screen bg-white flex flex-col">
            <Header />
            <main className="flex-grow">
              <Suspense fallback={<PageLoader />}>
                <ErrorBoundary>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:slug" element={<BlogPost />} />
                    <Route path="/admin" element={<AdminDashboard />} />
                    <Route path="/admin/login" element={<AdminLogin />} />
                    <Route path="/admin/blog" element={<BlogManagement />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    <Route path="/complaints" element={<Complaints />} />
                    <Route path="/partners" element={<Partners />} />
                    <Route path="/partner" element={<Partner />} />
                    <Route path="/affiliate" element={<Affiliate />} />
                    <Route path="/affiliate/login" element={<AffiliateLogin />} />
                    <Route path="/affiliate/dashboard" element={<AffiliateDashboard />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </ErrorBoundary>
              </Suspense>
            </main>
            <Footer />
          </div>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}